<template>
  <modal name="update-customer" class="modal-inner modal-update-customer" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true">
    <span class="close-button" @click="hide('update-customer')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      <span v-if="customerInfo">{{ customerInfo.name }}</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formUpdateCustomer" v-if="updateInfo != null">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2" v-if="user.role == 'admin'">
            <BaseInput v-model="updateInfo.customer_id" type="text" field_name="Kund ID" disabled readonly />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.name" type="text" field_name="Namn" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.company_number" type="text" field_name="Org. nummer" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full text-sm font-bold italic px-2 mb-4">
            Postadress
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.address" type="text" field_name="Adress" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.zip_code" type="text" field_name="Postnummer" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.city" type="text" field_name="Stad/Ort" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full text-sm font-bold italic px-2 mb-4">
            Fakturaadress
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.billing_address" type="text" field_name="Adress" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.billing_zip_code" type="text" field_name="Postnummer" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.billing_city" type="text" field_name="Stad/Ort" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.billing_reference" type="text" field_name="Ref" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.billing_invoice" type="text" field_name="E-Faktura" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full text-sm font-bold italic px-2 mb-4">
            Kontaktuppgifter
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.contact_name" type="text" field_name="Kontaktperson" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.phone" type="text" field_name="Telefon" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.email" type="text" field_name="Email" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2">
            <BaseTextarea rows="5" v-model="updateInfo.unit_description" field_name="Kundbeskrivning" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseSelect v-model="updateInfo.alarm_central" :options="alarmCenters" optionSelector="_id" nameSelector="name" field_name="Larmcentral" rules="required" :disabled="reseller && reseller.alarm_center != ''" :readonly="reseller && reseller.alarm_center != ''" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2" v-if="user.role == 'admin'">
            <BaseSelect v-model="updateInfo.seller" :options="sellers" optionSelector="_id" nameSelector="name" field_name="Säljare" />
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('update-customer')">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="updateCustomer()">Spara<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 800;

export default {
  props: {
    customerInfo: {
      required: true,
      default: null,
    },
    alarmCenters: {
      required: true,
      default: null,
    },
    sellers: {
      required: true,
      default: null,
    },
    reseller: {
      default: null,
    },
  },
  data() {
    return {
      updateInfo: null,
    };
  },
  watch: {
    customerInfo(newVal) {
      // eslint-disable-line
      this.updateInfo = _.cloneDeep(newVal);
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    updateCustomer() {
      this.$refs.formUpdateCustomer.validate().then((success) => {
        if (!success) {
          return;
        }

        let data = {
          name: this.updateInfo.name,
          company_number: this.updateInfo.company_number,
          address: this.updateInfo.address,
          zip_code: this.updateInfo.zip_code,
          city: this.updateInfo.city,
          billing_address: this.updateInfo.billing_address,
          billing_zip_code: this.updateInfo.billing_zip_code,
          billing_city: this.updateInfo.billing_city,
          billing_reference: this.updateInfo.billing_reference,
          billing_invoice: this.updateInfo.billing_invoice,
          contact_name: this.updateInfo.contact_name,
          phone: this.updateInfo.phone,
          email: this.updateInfo.email,
          unit_description: this.updateInfo.unit_description,
          alarm_central: this.updateInfo.alarm_central,
          seller: this.updateInfo.seller,
        };

        this.axios
          .put(`${process.env.VUE_APP_SERVER_URL}/customer/${this.updateInfo._id}`, data)
          .then(() => {
            //eslint-disable-line
            this.$emit("updateCustomer");
            this.$modal.hide("update-customer");
            this.handleSuccess();
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    hide(id) {
      this.$refs.formUpdateCustomer.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
