<template>
  <modal name="update-addon" class="modal-inner update-addon" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="resetForm">
    <span class="close-button" @click="hide('update-addon')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header">
      Tilläggstjänster <span v-if="customer">- {{ customer.name }}</span>
    </div>
    <div class="modal-body">
      <div v-if="isLoading" class="text-center text-xl"><BaseIcon icon="spinner" spin /></div>
      <div class="flex flex-col">
        <div v-if="customer">
          <div class="font-serif font-bold">{{ customer.name }} <span class="text-xxs italic font-light">(kund)</span></div>
          <div class="flex flex-wrap">
            <template v-for="a in addons">
              <div class="w-full md:w-1/5" v-bind:key="a.addon_id">
                <label class="inline-flex items-center mt-3">
                  <input v-model="customer.addons" :value="a.addon_id" type="checkbox" class="form-checkbox h-3 w-3" />
                  <span class="ml-2 text-gray-600 text-xs font-semibold">{{ a.name }}</span>
                </label>
              </div>
            </template>
          </div>
        </div>
        <div v-if="units.length > 0" class="border-b border-secondary-400 my-5"></div>
        <div v-if="units.length > 0">
          <div v-for="(u, i) in units" :key="u._id">
            <div class="font-serif font-semibold text-sm">{{ u.name }} <span class="text-xxs italic font-light">(verksamhet)</span></div>
            <div class="flex flex-wrap">
              <template v-for="a in addons">
                <div class="w-full md:w-1/5" v-bind:key="a.addon_id">
                  <label class="inline-flex items-center mt-3">
                    <input v-model="u.addons" :value="a.addon_id" type="checkbox" class="form-checkbox h-3 w-3" />
                    <span class="ml-2 text-gray-600 text-xs font-semibold">{{ a.name }}</span>
                  </label>
                </div>
              </template>
            </div>
            <div v-if="i != units.length - 1" class="border-b border-secondary-400 my-5"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('update-addon')">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="updateAddon()">Spara<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 1100;

export default {
  name: "AddonUpdate",
  props: {
    // todo: only pass customer id
    customerInfo: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      addons: [],
      customer: null,
      units: [],
    };
  },
  watch: {
    customerInfo(newVal) {
      if (newVal) this.getAddons();
    },
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    getAddons() {
      this.isLoading = true;

      if (this.customerInfo && this.customerInfo._id) {
        this.axios
          .get(`${process.env.VUE_APP_SERVER_URL}/addons/customer/${this.customerInfo._id}`)
          .then((response) => {
            this.addons = response.data.addons;
            this.customer = response.data.customer;
            this.units = response.data.units;

            this.isLoading = false;
          })
          .catch((error) => {
            this.handleError(error);
            this.isLoading = false;
          });
      }
    },

    async updateAddon() {
      try {
        let data = {
          customer: this.customer,
          units: this.units,
        };

        // eslint-disable-next-line
        let response = await this.axios.put(`${process.env.VUE_APP_SERVER_URL}/addons/customer/${this.customerInfo._id}`, data);

        this.$emit("updateAddon");
        this.$modal.hide("update-addon");
        this.handleSuccess();
      } catch (error) {
        this.handleError(error);
      }
    },

    hide(id) {
      this.$modal.hide(id);
      this.resetForm();
    },

    resetForm() {
      this.addonIds = [];
      this.customer = null;
      this.units = [];
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
