<template>
  <div>
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-right flex flex-wrap">
          <div class="mx-2 mt-2 lg:mt-0">
            <button class="btn-blue-outline mx-2 mt-3 sm:mt-0" @click.prevent="exportXLS()">
              {{ $t("export") }}
              <BaseIcon icon="file-excel" class="ml-1" />
            </button>
            <router-link class="btn-green" to="/admin/skapa-kund">
              {{ $t("new_customer") }}
              <BaseIcon icon="plus" class="ml-2" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="w-full mt-6">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="columns"
          :rows="customers"
          :search-options="{
            enabled: true,
            placeholder: 'Sök',
          }"
          :pagination-options="{
            enabled: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'name'">
              <router-link v-if="user.role == 'admin'" class="text-primary-500 font-bold underline hover:text-primary-600 transition duration-200" :to="'/startsida/' + props.row.customer_id">
                {{ props.row.name }}
              </router-link>
              <span v-else>{{ props.row.name }}</span>
            </span>
            <span v-else-if="props.column.field == 'agreement' && user.role == 'admin'">
              <router-link tag="button" class="btn-blue-outline inline-flex" :to="'/mina-avtal/customer/' + props.row.customer_id">
                {{ $t("agreement") }}
                <BaseIcon icon="file" class="ml-1" />
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'tounit'">
              <router-link tag="button" class="btn-blue-outline inline-flex" :to="'/verksamheter/customer/' + props.row.customer_id + '/-'">
                {{ $t("operation") }}
                <BaseIcon icon="city" class="ml-1" />
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'toaddon'">
              <button v-if="user.role == 'admin' || user.role == 'seller'" class="btn-blue-outline" @click.prevent="showAddon(props.row)">
                <BaseIcon icon="cube" />
              </button>
            </span>
            <span v-else-if="props.column.field == 'edit'">
              <button class="btn-secondary-outline" @click.prevent="show(props.row)">
                <BaseIcon icon="pencil-alt" />
              </button>
            </span>
            <span v-else-if="props.column.field == 'delete'">
              <button v-if="user.role == 'admin'" class="btn-red-outline" @click.prevent="deleteCustomerPrompt(props.row)">
                <BaseIcon icon="trash" />
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <CustomerUpdate :customerInfo="customer_info" :alarmCenters="alarm_centers" :sellers="sellers" @updateCustomer="getCustomers()" />
    <AddonsUpdate :customerInfo="customer_info" />
  </div>
</template>

<script>
import CustomerUpdate from "@/components/modal/customer_update";
import AddonsUpdate from "@/components/modal/addons_update";

export default {
  title() {
    return this.$t("page_titles.customer");
  },
  components: {
    CustomerUpdate,
    AddonsUpdate,
  },
  data() {
    return {
      user: this.$store.state.user,
      customers: [],
      alarm_centers: [],
      sellers: [],
      customer_info: null,
    };
  },

  methods: {
    getCustomers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/customer/`)
        .then((response) => {
          this.customers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getSellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/seller/reseller`)
        .then((response) => {
          this.sellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getAlarmCentrals() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central/`)
        .then((response) => {
          this.alarm_centers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    deleteCustomerPrompt(customer) {
      this.$modal.show("dialog", {
        title: `<span class="text-red-500">Ta bort kund ${customer.name}?</span><br>
                <span class="font-normal text-sm text-secondary-600">Ta bort kunden och alla deras verksamhet, larm, användare</span>`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">Avbryt</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">Radera</div>',
            handler: () => {
              this.deleteCustomer(customer._id);
            },
          },
        ],
      });
    },

    async deleteCustomer(_id) {
      try {
        // eslint-disable-next-line
        let response = await this.axios.delete(`${process.env.VUE_APP_SERVER_URL}/customer/${_id}`);

        this.$modal.hide("dialog");
        this.handleSuccess("Kund har tagits bort");

        this.getCustomers();
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    exportXLS() {
      let export_columns = [];

      this.customers.forEach((customer) => {
        export_columns.push({
          "Kund ID": customer.customer_id,
          Namn: customer.name,
          "Org. nummer": customer.company_number,
          Email: customer.email,
          Telefon: customer.phone,
          "Stad/Ort": customer.city,
        });
      });

      this.excelExport(export_columns, "kund", "kund_rapport");
    },

    getSellerName(id) {
      let s = _.find(this.sellers, { _id: id });
      if (s) return s.name;
      else return "";
    },

    show(customer) {
      this.customer_info = _.cloneDeep(customer);
      this.$modal.show("update-customer");
    },

    showAddon(customer) {
      this.customer_info = _.cloneDeep(customer);
      this.$modal.show("update-addon");
    },
  },
  computed: {
    columns() {
      return [
        { label: this.$t("customer_table.name"), field: "name", sortable: false },
        { label: this.$t("customer_table.seller"), field: "seller", sortable: false, formatFn: this.getSellerName, tdClass: "text-xxs" },
        { label: this.$t("customer_table.org_number"), field: "company_number", sortable: false },
        { label: this.$t("customer_table.email"), field: "email", sortable: false },
        { label: this.$t("customer_table.phone"), field: "phone", sortable: false },
        { label: this.$t("customer_table.city_town"), field: "city", sortable: false },
        { label: "", field: "agreement", sortable: false },
        { label: "", field: "tounit", sortable: false },
        { label: "", field: "toaddon", sortable: false },
        { label: "", field: "edit", sortable: false },
        { label: "", field: "delete", sortable: false },
      ];
    },
  },
  created() {
    this.getCustomers();
    this.getSellers();
    this.getAlarmCentrals();

    this.setPageTitle("Kunder", "customers_admin");
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.customer");
    },
  },
};
</script>
